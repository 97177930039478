// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recover__full {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} 

.recover__full .recover__form {
  width: 400px;
}

.recover__full .recover__formbutton {
  width: 100%;
}

.recover__full .recover__remember {
  text-align: left;
  color: #43464D;
}

.recover__full .ant-btn-primary {
  background: #43464D;
  border-color: #43464D;
}

.recover__full .ant-btn-primary:hover, 
.recover__full .ant-btn-primary:focus {
  background:  rgba(192, 57, 43, .75);
  border-color: rgba(192, 57, 43, .8);
}

.recover__full .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #43464D;
  background: #43464D;
}

.recover__full .ant-checkbox-input:hover {
  border-color: #43464D;
  background: #43464D;
}

.recover__full .recover__header {
  width: 400px;
  font-weight: 200;
  font-size: .75em;
  text-align: left;
  margin-bottom: 2vh;
}

.recover__recover {
  padding-top: 15px;
  color: rgba(0, 0, 0, .75);
}

.recover__showAlreadyMember{
  display: none;
}

.recover__iRemember {
  padding-top: 15px;
  color: rgba(0, 0, 0, .75);
}`, "",{"version":3,"sources":["webpack://./src/components/PasswordRecover/PasswordRecover.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;;EAEE,mCAAmC;EACnC,mCAAmC;AACrC;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".recover__full {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n} \n\n.recover__full .recover__form {\n  width: 400px;\n}\n\n.recover__full .recover__formbutton {\n  width: 100%;\n}\n\n.recover__full .recover__remember {\n  text-align: left;\n  color: #43464D;\n}\n\n.recover__full .ant-btn-primary {\n  background: #43464D;\n  border-color: #43464D;\n}\n\n.recover__full .ant-btn-primary:hover, \n.recover__full .ant-btn-primary:focus {\n  background:  rgba(192, 57, 43, .75);\n  border-color: rgba(192, 57, 43, .8);\n}\n\n.recover__full .ant-checkbox-checked .ant-checkbox-inner {\n  border-color: #43464D;\n  background: #43464D;\n}\n\n.recover__full .ant-checkbox-input:hover {\n  border-color: #43464D;\n  background: #43464D;\n}\n\n.recover__full .recover__header {\n  width: 400px;\n  font-weight: 200;\n  font-size: .75em;\n  text-align: left;\n  margin-bottom: 2vh;\n}\n\n.recover__recover {\n  padding-top: 15px;\n  color: rgba(0, 0, 0, .75);\n}\n\n.recover__showAlreadyMember{\n  display: none;\n}\n\n.recover__iRemember {\n  padding-top: 15px;\n  color: rgba(0, 0, 0, .75);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
