// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(17px + 1vmin);
  letter-spacing: 1.5px;
  background-color: #d9d6cb;
}

.App a:link, a:visited {
  color: rgba(229, 152, 102, .75);
}

.App a:hover {
  color: rgba(229, 152, 102, .75);
}

.link {
  color: rgba(229, 152, 102, 1);
  cursor: pointer;
} 

.link:hover {
  color: rgba(229, 152, 102, .75);
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["@import \"~antd/dist/antd.css\";\n\n.App {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(17px + 1vmin);\n  letter-spacing: 1.5px;\n  background-color: #d9d6cb;\n}\n\n.App a:link, a:visited {\n  color: rgba(229, 152, 102, .75);\n}\n\n.App a:hover {\n  color: rgba(229, 152, 102, .75);\n}\n\n.link {\n  color: rgba(229, 152, 102, 1);\n  cursor: pointer;\n} \n\n.link:hover {\n  color: rgba(229, 152, 102, .75);\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
