// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.taskedit__container {
  margin: 1rem 1.5rem;
  height: calc(100% - 3.5rem - 65px - 70px - 3rem); /* header, listHeader, listFooter, margin-bottom  */  
  overflow: scroll;
}

.taskedit__footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 2px solid #d9d6cb;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.3rem;
  padding: 1rem;
  height: 70px;
  border-radius: 0rem;
}

.taskedit__footerLeft {
  display: inline-block;
  font-weight: 200;
  padding-left: 1rem;
  width: 30%;
}

.taskedit__footerRight {
  display: inline-block;
  text-align: right;
  padding-right: 0.5rem;
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .taskedit__container {
    margin: .5rem 1rem;
  }
  .taskedit__footerLeft {
    width: 50%;
  }
  .taskedit__footerRight {
    width: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/TaskEdit/TaskEdit.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gDAAgD,EAAE,mDAAmD;EACrG,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,6BAA6B;EAC7B,wCAAwC;EACxC,qBAAqB;EACrB,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".taskedit__container {\n  margin: 1rem 1.5rem;\n  height: calc(100% - 3.5rem - 65px - 70px - 3rem); /* header, listHeader, listFooter, margin-bottom  */  \n  overflow: scroll;\n}\n\n.taskedit__footer {\n  position: absolute;\n  width: 100%;\n  bottom: 0;\n  border-top: 2px solid #d9d6cb;\n  background-color: rgba(255, 255, 255, 1);\n  border-radius: 0.3rem;\n  padding: 1rem;\n  height: 70px;\n  border-radius: 0rem;\n}\n\n.taskedit__footerLeft {\n  display: inline-block;\n  font-weight: 200;\n  padding-left: 1rem;\n  width: 30%;\n}\n\n.taskedit__footerRight {\n  display: inline-block;\n  text-align: right;\n  padding-right: 0.5rem;\n  width: 70%;\n}\n\n@media only screen and (max-width: 600px) {\n  .taskedit__container {\n    margin: .5rem 1rem;\n  }\n  .taskedit__footerLeft {\n    width: 50%;\n  }\n  .taskedit__footerRight {\n    width: 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
