// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../img/opendoor.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emailVerified__leftPanel {
  width: 40vw;  
  height: 100vh;
  display: inline-block;
  vertical-align: top;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.emailVerified__rightPanel {
  width: 60vw;
  height: 100vh;
  display: inline-block;
  vertical-align: top;
  background-color: white;
}

.emailVerified__container{
  height: 100%;
  padding: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: .5em;
  text-align: justify;
}

.emailVerified__link {
  padding-top: 15px;
  color: rgba(0, 0, 0, .75);
}

.emailVerified__loader {
  font-size: 5em;
}

`, "",{"version":3,"sources":["webpack://./src/pages/EmailVerified/EmailVerified.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,yDAAiD;EACjD,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".emailVerified__leftPanel {\n  width: 40vw;  \n  height: 100vh;\n  display: inline-block;\n  vertical-align: top;\n  background-image: url(\"./../../img/opendoor.jpg\");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.emailVerified__rightPanel {\n  width: 60vw;\n  height: 100vh;\n  display: inline-block;\n  vertical-align: top;\n  background-color: white;\n}\n\n.emailVerified__container{\n  height: 100%;\n  padding: 20%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: .5em;\n  text-align: justify;\n}\n\n.emailVerified__link {\n  padding-top: 15px;\n  color: rgba(0, 0, 0, .75);\n}\n\n.emailVerified__loader {\n  font-size: 5em;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
