// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup__full {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} 

.signup__full .signup__form {
  width: 400px;
  max-width: 85vw;
}

.signup__full .signup__formbutton {
  width: 100%;
}

.signup__full .signup__terms {
  text-align: left;
  color: #43464D;
}

.signup__full .ant-btn-primary {
  background: #43464D;
  border-color: #43464D;
}

.signup__full .ant-btn-primary:hover, 
.signup__full .ant-btn-primary:focus {
  background:  rgba(229, 152, 102, .75);
  border-color: rgba(229, 152, 102, .8);
}

.signup__full .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #43464D;
  background: #43464D;
}

.signup__full .ant-checkbox-input:hover {
  border-color: #43464D;
  background: #43464D;
}

.signup__full .signup__header {
  width: 400px;
  max-width: 85vw;
  font-weight: 200;
  font-size: .75em;
  text-align: left;
  margin-bottom: 2vh;
}

.signup__showAlreadyMember{
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/SignUpForm/SignUpForm.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;;EAEE,qCAAqC;EACrC,qCAAqC;AACvC;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".signup__full {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n} \n\n.signup__full .signup__form {\n  width: 400px;\n  max-width: 85vw;\n}\n\n.signup__full .signup__formbutton {\n  width: 100%;\n}\n\n.signup__full .signup__terms {\n  text-align: left;\n  color: #43464D;\n}\n\n.signup__full .ant-btn-primary {\n  background: #43464D;\n  border-color: #43464D;\n}\n\n.signup__full .ant-btn-primary:hover, \n.signup__full .ant-btn-primary:focus {\n  background:  rgba(229, 152, 102, .75);\n  border-color: rgba(229, 152, 102, .8);\n}\n\n.signup__full .ant-checkbox-checked .ant-checkbox-inner {\n  border-color: #43464D;\n  background: #43464D;\n}\n\n.signup__full .ant-checkbox-input:hover {\n  border-color: #43464D;\n  background: #43464D;\n}\n\n.signup__full .signup__header {\n  width: 400px;\n  max-width: 85vw;\n  font-weight: 200;\n  font-size: .75em;\n  text-align: left;\n  margin-bottom: 2vh;\n}\n\n.signup__showAlreadyMember{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
