// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome__container {
  width: 100vw;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.welcome__alreadyMember{
    position: absolute;
    right: 1.5em;
    font-size: .5em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Welcome/Welcome.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".welcome__container {\n  width: 100vw;\n  height: 100%;\n  display: inline-block;\n  vertical-align: top;\n}\n\n.welcome__alreadyMember{\n    position: absolute;\n    right: 1.5em;\n    font-size: .5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
