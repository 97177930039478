// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../img/pool.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newPassword__leftPanel {
  width: 40vw;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.newPassword__rightPanel {
  width: 60vw;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NewPassword/NewPassword.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,yDAA6C;EAC7C,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".newPassword__leftPanel {\n  width: 40vw;\n  height: 100%;\n  display: inline-block;\n  vertical-align: top;\n  background-image: url(\"./../../img/pool.jpg\");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.newPassword__rightPanel {\n  width: 60vw;\n  height: 100%;\n  display: inline-block;\n  vertical-align: top;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
