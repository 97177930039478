// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.detailRow {
    position: relative;
    padding-bottom: 1rem;
    margin-left: 3rem;
    width: calc(100% - 3rem);
}

.detailRow__text {
    font-size: .75em;
    font-weight: 200;  
}

@media only screen and (max-width: 600px) {
    .detailRow {
        margin-left: 2.5rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/ListRow/DetailRow/DetailRow.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":["\n.detailRow {\n    position: relative;\n    padding-bottom: 1rem;\n    margin-left: 3rem;\n    width: calc(100% - 3rem);\n}\n\n.detailRow__text {\n    font-size: .75em;\n    font-weight: 200;  \n}\n\n@media only screen and (max-width: 600px) {\n    .detailRow {\n        margin-left: 2.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
