// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    position: fixed;
    top: 0;
    height: 3.8rem;
    padding-top: 1rem;
    border-bottom: 1px solid #bebbb2;
    width: calc(100vw - 7rem);
}

.header__logo {
    margin: 0rem .5rem 0rem 1rem;
    width: 25px;
    height: 25px;
    opacity: .5;
}

.header__title {
    opacity: .5;
}

.header__motto {
    display: auto;
    font-weight: 200;
    opacity: .5;
}

@media only screen and (max-width: 600px) {
    .header {
        width: 100vw;
    }
    .header__motto {
        display: none; 
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,cAAc;IACd,iBAAiB;IACjB,gCAAgC;IAChC,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,WAAW;IACX,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".header {\n    position: fixed;\n    top: 0;\n    height: 3.8rem;\n    padding-top: 1rem;\n    border-bottom: 1px solid #bebbb2;\n    width: calc(100vw - 7rem);\n}\n\n.header__logo {\n    margin: 0rem .5rem 0rem 1rem;\n    width: 25px;\n    height: 25px;\n    opacity: .5;\n}\n\n.header__title {\n    opacity: .5;\n}\n\n.header__motto {\n    display: auto;\n    font-weight: 200;\n    opacity: .5;\n}\n\n@media only screen and (max-width: 600px) {\n    .header {\n        width: 100vw;\n    }\n    .header__motto {\n        display: none; \n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
