// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login__full {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} 

.login__full .login__form {
  width: 400px;
  max-width: 85vw;
}

.login__full .login__logo {
  margin-bottom: 10px;
  width: 65px;
  height: 65px;
}

.login__full .login__formbutton {
  width: 100%;
}

.login__full .login__remember {
  text-align: left;
  color: #43464D;
}

.login__full .ant-btn-primary {
  background: #43464D;
  border-color: #43464D;
}

.login__full .ant-btn-primary:hover, 
.login__full .ant-btn-primary:focus {
  background:  rgba(229, 152, 102, .75);
  border-color: rgba(229, 152, 102, .8);
}

.login__full .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #43464D;
  background: #43464D;
}

.login__full .ant-checkbox-input:hover {
  border-color: #43464D;
  background: #43464D;
}

.login__full .login__header {
  width: 400px;
  font-weight: 200;
  font-size: .75em;
  text-align: center;
  margin-bottom: 2vh;
}

.login__login {
  padding-top: 15px;
  color: rgba(0, 0, 0, .75);
}

.login__showAlreadyMember{
  display: none;
}

.login__verifyEmailLink {
  padding-top: 15px;
  color: rgba(0, 0, 0, .75);
}
`, "",{"version":3,"sources":["webpack://./src/components/LoginForm/LoginForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;;EAEE,qCAAqC;EACrC,qCAAqC;AACvC;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".login__full {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n} \n\n.login__full .login__form {\n  width: 400px;\n  max-width: 85vw;\n}\n\n.login__full .login__logo {\n  margin-bottom: 10px;\n  width: 65px;\n  height: 65px;\n}\n\n.login__full .login__formbutton {\n  width: 100%;\n}\n\n.login__full .login__remember {\n  text-align: left;\n  color: #43464D;\n}\n\n.login__full .ant-btn-primary {\n  background: #43464D;\n  border-color: #43464D;\n}\n\n.login__full .ant-btn-primary:hover, \n.login__full .ant-btn-primary:focus {\n  background:  rgba(229, 152, 102, .75);\n  border-color: rgba(229, 152, 102, .8);\n}\n\n.login__full .ant-checkbox-checked .ant-checkbox-inner {\n  border-color: #43464D;\n  background: #43464D;\n}\n\n.login__full .ant-checkbox-input:hover {\n  border-color: #43464D;\n  background: #43464D;\n}\n\n.login__full .login__header {\n  width: 400px;\n  font-weight: 200;\n  font-size: .75em;\n  text-align: center;\n  margin-bottom: 2vh;\n}\n\n.login__login {\n  padding-top: 15px;\n  color: rgba(0, 0, 0, .75);\n}\n\n.login__showAlreadyMember{\n  display: none;\n}\n\n.login__verifyEmailLink {\n  padding-top: 15px;\n  color: rgba(0, 0, 0, .75);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
