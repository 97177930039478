// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.actionRowHeader {
    position: relative;
    display: inline-block;
    text-align: right;
    vertical-align: top;
}

.actionRowHeader__more {
    cursor: pointer;
}

.actionRowHeader__more:hover {
    color: rgba(229, 152, 102, 1);
}

.actionRowHeader__moreContainer {   
    display: inline-block;
    vertical-align: top;
    text-align: center; 
    width: 30px;     
}

.actionRowHeader__SwitchContainer {
    display: inline-block;
    text-align: right;
    width: 140px;
    vertical-align: top;
  } 

.actionRowHeader__Switch {
    display: inline-block;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Home/ListHeader/ActionRowHeader/ActionRowHeader.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;EACrB;;AAEF;IACI,qBAAqB;EACvB","sourcesContent":["\n.actionRowHeader {\n    position: relative;\n    display: inline-block;\n    text-align: right;\n    vertical-align: top;\n}\n\n.actionRowHeader__more {\n    cursor: pointer;\n}\n\n.actionRowHeader__more:hover {\n    color: rgba(229, 152, 102, 1);\n}\n\n.actionRowHeader__moreContainer {   \n    display: inline-block;\n    vertical-align: top;\n    text-align: center; \n    width: 30px;     \n}\n\n.actionRowHeader__SwitchContainer {\n    display: inline-block;\n    text-align: right;\n    width: 140px;\n    vertical-align: top;\n  } \n\n.actionRowHeader__Switch {\n    display: inline-block;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
