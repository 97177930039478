// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.actionRow {
    margin-top: 1rem;
    position: relative;
    vertical-align: top;
    text-align: right;
}

.actionRow__actionContainer {
    display: inline-block;
    vertical-align: top;
    text-align: right;    
    width: 100%;
}

.actionRow__action {
    text-align: center;
    display: inline-block;
    cursor: pointer;
    background-color: rgb(217, 214, 203, .5);
    border: 1px solid rgb(217, 214, 203, .75);
    width: 33%;
}

.actionRow__action:hover {
    text-align: center;
    display: inline-block;
    cursor: pointer;
    background-color: rgb(255, 255, 255, .25);
    width: 33%;
}

.actionRow__actionMiddleMargin {
    margin: 0 .5% 0;
}

.actionRow__actionTitle {
    font-size: .75em;
    font-weight: 200;  
}

.actionRow__action:hover {
    color: rgba(229, 152, 102, 1);
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/ListRow/ActionRow/ActionRow.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,wCAAwC;IACxC,yCAAyC;IACzC,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,yCAAyC;IACzC,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":["\n.actionRow {\n    margin-top: 1rem;\n    position: relative;\n    vertical-align: top;\n    text-align: right;\n}\n\n.actionRow__actionContainer {\n    display: inline-block;\n    vertical-align: top;\n    text-align: right;    \n    width: 100%;\n}\n\n.actionRow__action {\n    text-align: center;\n    display: inline-block;\n    cursor: pointer;\n    background-color: rgb(217, 214, 203, .5);\n    border: 1px solid rgb(217, 214, 203, .75);\n    width: 33%;\n}\n\n.actionRow__action:hover {\n    text-align: center;\n    display: inline-block;\n    cursor: pointer;\n    background-color: rgb(255, 255, 255, .25);\n    width: 33%;\n}\n\n.actionRow__actionMiddleMargin {\n    margin: 0 .5% 0;\n}\n\n.actionRow__actionTitle {\n    font-size: .75em;\n    font-weight: 200;  \n}\n\n.actionRow__action:hover {\n    color: rgba(229, 152, 102, 1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
